import OpenAI from 'openai';
import { fal } from '@fal-ai/client';
import { supabase } from '../lib/supabase';

// Environment variables and API keys.
const openAiApiKey = import.meta.env.VITE_OPENAI_API_KEY;

const openai = new OpenAI({
  apiKey: openAiApiKey,
  dangerouslyAllowBrowser: true
});


/** Logging Helpers */
function logApiDetails(stage, details) {
  console.group(`API [${stage}] - ${new Date().toISOString()}`);
  console.log('Details:', details);
  console.groupEnd();
}
function logApiError(stage, error) {
  console.group(`❌ API Error [${stage}] - ${new Date().toISOString()}`);
  console.error('Error Details:', error);
  console.groupEnd();
}

/** Convert a data URI to a Blob */
function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}

/** Upload an image (given as base64 without header) to Supabase Storage and return its public URL */
async function uploadImage(base64Data) {
  const filename = `image-${Date.now()}.jpg`;
  const blob = dataURItoBlob(`data:image/jpeg;base64,${base64Data}`);
  const { error: uploadError } = await supabase.storage
    .from('images')
    .upload(filename, blob, { contentType: 'image/jpeg' });
  if (uploadError) throw uploadError;

  const { data: urlData, error: urlError } = supabase.storage
    .from('images')
    .getPublicUrl(filename);
  if (urlError) throw urlError;
  if (!urlData?.publicUrl) throw new Error("Public URL not found for uploaded image");
  return urlData.publicUrl;
}

/** Parse the text response from the smile analysis into an object. */
function parseTextResponse(text) {
  const sections = text.split('\n\n').filter(Boolean);
  const result = {};

  sections.forEach(section => {
    if (section.startsWith('Treatment:')) {
      result.treatment = section.replace(/^Treatment:\s*/, '').trim();
    } else if (section.startsWith('Prompt:')) {
      result.prompt = section.replace(/^Prompt:\s*/, '').trim();
    } else {
      const lines = section.split('\n').map(line => line.trim());
      const match = lines[0]?.match(/^(Colour|Shape|Spacing|Alignment):\s*(\d+)/i);
      if (match) {
        const category = match[1].toLowerCase();
        const score = parseInt(match[2], 10);
        const description = lines.slice(1)
          .filter(line => line.startsWith('+') || line.startsWith('-'))
          .join('\n') ||
          lines.find(line => line.startsWith('Description:'))?.replace(/^Description:\s*/i, '') ||
          'Analysis not available';
        result[category] = { score: Math.min(Math.max(score, 0), 100), description };
      }
    }
  });

  return {
    colour: result.colour || { score: 0, description: 'Analysis not available' },
    shape: result.shape || { score: 0, description: 'Analysis not available' },
    spacing: result.spacing || { score: 0, description: 'Analysis not available' },
    alignment: result.alignment || { score: 0, description: 'Analysis not available' },
    treatment: result.treatment || 'Treatment recommendation not available',
    prompt: result.prompt
  };
}

/**
 * Extract image attributes (gender, facial_hair, complexion) using GPT-4o-mini.
 */
async function getImageAttributes(imageBase64) {
  const attributesPayload = {
    model: "gpt-4o-mini",
    messages: [
      {
        role: "system",
        content: `You are a machine learning model that processes facial images for academic evaluation purposes. Your task is to extract three attributes from the given image:
- "gender": either "F" for Female or "M" for Male.
- "facial_hair": a boolean indicating whether noticeable facial hair is present.
- "complexion": an integer representing the complexion rating: 1 (Light), 2 (Medium), 3 (Tan/Olive), or 4 (Dark).

Output only a single JSON object with these exact keys and values. Do not include any additional text, commentary, or explanation.`
      },
      {
        role: "user",
        content: [
          { type: "text", text: "Analyze the image and return only the JSON object as described." },
          { type: "image_url", image_url: { url: `data:image/jpeg;base64,${imageBase64}` } }
        ]
      }
    ],
    max_tokens: 100,
    temperature: 0.0
  };

  const response = await openai.chat.completions.create(attributesPayload);
  const attributesText = response.choices[0]?.message?.content;
  if (!attributesText) throw new Error("No attribute analysis received from GPT-4o-mini");
  const cleanedText = attributesText.replace(/```(json)?/gi, '').trim();

  try {
    const attributes = JSON.parse(cleanedText);
    if (typeof attributes.gender !== 'string' || (attributes.gender !== 'F' && attributes.gender !== 'M')) {
      throw new Error("Invalid value for gender");
    }
    if (typeof attributes.facial_hair !== 'boolean') {
      throw new Error("Invalid value for facial_hair");
    }
    if (typeof attributes.complexion !== 'number' || ![1, 2, 3, 4].includes(attributes.complexion)) {
      throw new Error("Invalid value for complexion");
    }
    return attributes;
  } catch (err) {
    throw new Error("Could not parse attributes: " + err.message);
  }
}

/**
 * Look up an image in the 'smile_images' table matching the given image attributes.
 */
async function searchImageAttributesInDB(imageAttributes) {
  try {
    const { data, error } = await supabase
      .from('smile_images')
      .select('gender, facial_hair, complexion, image_url')
      .eq('gender', imageAttributes.gender)
      .eq('facial_hair', imageAttributes.facial_hair)
      .eq('complexion', imageAttributes.complexion)
      .limit(1)
      .maybeSingle();
    if (error) throw error;
    return data;
  } catch (error) {
    logApiError('Supabase Search for Image Attributes', error);
    return null;
  }
}

/**
 * Analyzes a smile image by performing the following steps:
 *  - Loads the image and gets dimensions.
 *  - Uploads the original image.
 *  - Extracts facial attributes via GPT-4o-mini.
 *  - Searches the database for a matching enhanced image.
 *  - Sends the (uncropped) image for smile analysis.
 *  - Returns the analysis results along with image info.
 */
export async function analyzeSmileImage(
  imageBase64,
  firstName,
  age,
  gender,
  budget,
  email,
  location,
  concerns
) {
  try {
    logApiDetails('Starting Analysis', {
      hasImage: !!imageBase64,
      imageSize: imageBase64.length,
      budget,
      hasEmail: !!email,
      hasLocation: !!location,
      concerns
    });

    // Remove data URI header if present.
    const cleanBase64 = imageBase64.replace(/^data:image\/\w+;base64,/, '');
    const img = new Image();
    img.src = `data:image/jpeg;base64,${cleanBase64}`;
    await new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = () => reject(new Error("Error loading image"));
    });
    logApiDetails('Image Dimensions', { width: img.naturalWidth, height: img.naturalHeight });

    // Upload original image.
    const originalImageUrl = await uploadImage(cleanBase64);
    console.log("[Uploaded] Original image URL:", originalImageUrl);

    // Retrieve image attributes.
    const imageAttributes = await getImageAttributes(cleanBase64);
    logApiDetails('Image Attributes Retrieved', { imageAttributes });

    // Look up an enhanced image based on attributes; fallback to the original if none is found.
    const dbRecord = await searchImageAttributesInDB(imageAttributes);
    const finalEnhancedImageUrl = dbRecord?.image_url || originalImageUrl;

    // No cropping is performed here, so we use the original image as the cropped image.
    const croppedBase64 = cleanBase64;

    // Request smile analysis from the AI.
    const smileAnalysisPayload = {
      model: "gpt-4o-mini",
      messages: [
        {
          role: "system",
          content: `You are a dental expert evaluating my smile aesthetics for educational purposes. For each category, give a concise analysis in plain language and a score between 60 and 95. If no smile is clearly visible, assign 0 to all categories.

Format your response EXACTLY as follows:

Colour: [score]
Description:
+ [positive point about tooth colour]
- [negative point or area for improvement, be specific]
- [negative point or area for improvement, be specific]

Shape: [score]
Description:
+ [positive point about smile curve/tooth shapes, be specific]
- [negative point or area for improvement]
- [negative point or area for improvement, be specific]

Spacing: [score]
Description:
+ [positive point about teeth spacing, be specific]
- [negative point or area for improvement, be specific]
- [negative point or area for improvement]

Alignment: [score]
Description:
+ [positive point about teeth alignment, be specific]
- [negative point or area for improvement, be specific]
- [negative point or area for improvement]

Treatment: [A very short sentence summary of the most suitable treatment option to enhance your smile. Write with authority and urgency.]

Do not include any asterisks whatsoever in the response.`
        },
        {
          role: "user",
          content: [
            { type: "text", text: "Please provide a cosmetic smile evaluation for the cropped image." },
            { type: "image_url", image_url: { url: `data:image/jpeg;base64,${croppedBase64}` } }
          ]
        }
      ],
      max_tokens: 500
    };

    const smileResponse = await openai.chat.completions.create(smileAnalysisPayload);
    const smileAnalysisText = smileResponse.choices[0]?.message?.content;
    if (!smileAnalysisText) throw new Error("No analysis received from OpenAI for the smile image");
    logApiDetails('Smile Analysis Received', { smileAnalysisText });

    const analysisResult = parseTextResponse(smileAnalysisText);

    return {
      analysis: analysisResult,
      imageUrl: finalEnhancedImageUrl,
      croppedBase64,
      imageAttributes
    };

  } catch (error) {
    logApiError('Analysis', error);
    if (error?.code === 'model_not_found') {
      throw new Error("The AI model is currently unavailable. Please try again later.");
    } else if (error?.code === 'context_length_exceeded') {
      throw new Error("The image is too complex to analyze. Please try a different photo.");
    } else if (error?.status === 429) {
      throw new Error("Too many requests. Please wait a moment and try again.");
    } else if (error instanceof Error) {
      throw new Error(error.message);
    }
    throw new Error("An unexpected error occurred during analysis. Please try again.");
  }
}

/** Type definition for the analysis result if needed externally. */
export interface AnalysisResult {
  colour: { score: number; description: string; };
  shape: { score: number; description: string; };
  spacing: { score: number; description: string; };
  alignment: { score: number; description: string; };
  treatment: string;
  prompt?: string;
}
